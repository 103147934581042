import React from "react";
import LoadingAnimation from './../images/coca-cola-beats.gif';
const Loading = () => {
    return (
        <>
      <div className='loading  '>
        <img alt="loading" src={LoadingAnimation} style ={{backgroundColor:"red", mixBlendMode:"lighten"}}/>
        {/* <h3>Loading...</h3> */}
      </div>
   
      </>
    );
}

export default Loading;