import React, { useState } from "react";
import leftImg from "./../images/cc-left.png";
import rightImg from "./../images/cc-right.png";

const AccessExpired = (props) => {
    const [lang, setLang] = useState("al");

    const handleLangChange = (e) => {
        e.preventDefault();
        if (lang === "al") {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "en");
                setLang("en");
            }, 200);
        } else {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "al");
                setLang("al");
            }, 200);
        }
    };

    return (
        <>
            <div className="container langWrapper my-4">
                <button className="form-control" onClick={handleLangChange}>
                    {lang === "en" ? "English" : "Shqip"}
                </button>
            </div>
            <div className="container success-container mx-auto">
                <img src={leftImg} alt="" className="leftImg" />

                {lang === "al" ? (
                    <div className="alert alert-warning">
                        <h3 className="alert-heading text-center mb-3">
                            LOJA KA PËRFUNDUAR! JU LUTEMI TË PROVONI VITIN TJETËR.
                        </h3>
                        <hr />
                        <h5 className="text-center">
                            JU FALEMNDERIT PËR PJESËMARRJEN TUAJ! NËSE KENI NDONJË PYETJE, KONTAKTONI QENDRËN TONË TË KONTAKTIT.
                        </h5>
                        <hr />
                        <p className="text-center contact_us_info">
                            Për më shumë informacione, ju lutemi telefononi qendrën tonë të kontaktit <br />{" "}
                            <a className="nav_link" href="tel:+38349745570">
                                +383 (0) 49-745-570
                            </a>
                        </p>
                    </div>
                ) : (
                    <div className="alert alert-warning">
                        <h3 className="alert-heading text-center mb-3">
                            THE GAME IS OVER! PLEASE TRY AGAIN NEXT YEAR.
                        </h3>
                        <hr />
                        <h5 className="text-center">
                            THANK YOU FOR YOUR PARTICIPATION! IF YOU HAVE ANY QUESTIONS, PLEASE CONTACT OUR CALL CENTER.
                        </h5>
                        <hr />
                        <p className="text-center contact_us_info">
                            For more information, please call our contact center <br />{" "}
                            <a className="nav_link" href="tel:+38349745570">
                                +383 (0) 49-745-570
                            </a>
                        </p>
                    </div>
                )}
                <img src={rightImg} alt="" className="rightImg" />
            </div>
        </>
    );
};

export default AccessExpired;
