import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Button, colors, Alert
} from '@mui/material';
import nje from "../../images/1.jpg"
import dy from "../../images/2.jpg"
import tre from "../../images/3.jpg"
import kater from "../../images/4.jpg"
import fototShkurt from "../../images/validTicketPhoto.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HowToPlayModalDialog = ({ isOpen, handleModalClose, lang }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    return (

        <Dialog open={isOpen} onClose={handleModalClose} >
            <DialogTitle className=' text-center modalBackground'>
                {lang == 'al' ?
                    "Si të luaj?"
                    : "How to play?"
                }
            </DialogTitle>

            <DialogContent className='modalBackground'>

            <Slider {...sliderSettings}>
                    <div>
                        <img src={dy} alt="Slide 1" className="img-fluid w-75 text-center mx-auto" />
                    </div>
                    <div>
                        <img src={nje} alt="Slide 2" className="img-fluid w-75 text-center mx-auto" />
                    </div>
                    <div>
                        <img src={tre} alt="Slide 2" className="img-fluid w-75 text-center mx-auto" />
                    </div>
                    <div>
                        <img src={kater} alt="Slide 2" className="img-fluid w-75 text-center mx-auto" />
                    </div>
                </Slider>
                <Button onClick={handleModalClose} variant="contained" color="error">
                    {lang == 'al' ? "Mbylle" : "Close"}
                </Button>
            </DialogContent>
        </Dialog >
    );
};

export default HowToPlayModalDialog;
