import React, { useEffect, useState } from "react";
import leftImg from './../images/cc-left.png';
import rightImg from './../images/cc-right.png';
import termsImage from './../images/terms.png';
import Loading from "./loading";
const Terms = () => {
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState("al");
  const goBackHandler = () => {
    window.history.back();
  };
  useEffect(() => { setTimeout(() => { setLoading(false) }, 1500) }, [])


  const handleLangChange = (e) => {
    e.preventDefault();
    if (lang === "al") {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "en");
        setLang("en");
      }, 200);
    } else {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "al");
        setLang("al");
      }, 200);
    }
  };
  if (loading) {
    return (
      <Loading />
    )
  } else
    return (
      <>
        <div className=" container langWrapper my-4">
          <button className="form-control " onClick={handleLangChange}>
            {lang === "en" ? "English" : "Shqip"}
          </button>
          <button className="form-control " onClick={goBackHandler}>
            Go Back
          </button>
        </div>
        <div class="container success-container  mx-auto">
          <img src={leftImg} alt="letCCImg" className="leftImg" />
          <div className="alert alert-success">
            <h3 class="alert-heading text-center mb-3">Terms and Conditions</h3>
            <h5 className="text-center">Please Read Carefully!</h5>
            <hr />
            <div className='formControl overflow-auto1 ' id="style-2" style={{ overflow: 'auto', maxHeight: '65vh' }}>
              {lang == 'al' ?
                <>
                  <p>"COKE NEW YEAR" Loja shp&euml;rblyese</p>
                  <p>&nbsp;</p>
                  <p>ORGANIZATORI</p>
                  <p>&nbsp;</p>
                  <p>Neni 1</p>
                  <p>Coca-Cola Hellenic Bottling Company &ndash; Kosovo me adres&euml; n&euml; Lapnasell&euml;, Kilometri i Pest&euml; i rrug&euml;s Prishtin&euml;- Shkup, numri i TVSH 600299384, &euml;sht&euml; organizator i loj&euml;s "COKE NEW YEAR&rdquo; (nga k&euml;tu e shprehur si &ldquo;Loja Shp&euml;rblyese&rdquo;). K&euml;to rregulla jan&euml; t&euml; vlefshme p&euml;r t&euml; gjith&euml; pjes&euml;marr&euml;sit.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>EMRI ZYRTAR I LOJ&Euml;S SHP&Euml;RBLYESE</p>
                  <p>&nbsp;</p>
                  <p>Neni 2</p>
                  <p>Emri zyrtar i Loj&euml;s Shp&euml;rblyese &euml;sht&euml; "Coke New Year".</p>
                  <p>&nbsp;</p>
                  <p>TERRITORI</p>
                  <p>&nbsp;</p>
                  <p>Neni 3</p>
                  <p>Loja Shp&euml;rblyese do t&euml; zhvillohet ekskluzivisht brenda territorit t&euml; Kosov&euml;s.</p>
                  <p>&nbsp;</p>
                  <p>KOH&Euml;ZGJATJA E LOJ&Euml;S SHP&Euml;RBLYESE</p>
                  <p>&nbsp;</p>
                  <p>Neni 4</p>
                  <p>Coke New Year fillon m&euml; 25 n&euml;ntor 2024, n&euml; ora 00:01, dhe p&euml;rfundon m&euml; 25 dhjetor 2024, n&euml; ora 23:59, sipas k&euml;tyre rregullave.</p>
                  <p>&nbsp;</p>
                  <p>PAKETIMET PROMOCIONALE DHE Q&Euml;LLIMI I ORGANIZIMIT</p>
                  <p>&nbsp;</p>
                  <p>Neni 5</p>
                  <p>Produktet dhe paketimet pjes&euml;marr&euml;se, t&euml; quajtura bashk&euml;risht "Paketimet Promocionale", p&euml;rfshijn&euml; Coca Cola Red 0.25L n&euml; shishe xhami t&euml; kthyeshme (n&euml; vijim: RGB), Coca Cola Zero 0.25L RGB, Coca Cola Red 0.33L, Coca Cola Zero 0.33L dhe Coca Cola Cherry 0.33L n&euml; kana&ccedil;e, si dhe Coca Cola Red 0.45L, Coca Cola Zero 0.45L, Coca Cola Red 1.25L, Coca Cola Zero 1.25L, Coca Cola Cherry 1.25L, Coca Cola Red 2L dhe Coca Cola Zero 2L n&euml; shishe plastike t&euml; pakthyeshme. Loja e Shp&euml;rblimit ka p&euml;r q&euml;llim promovimin e produkteve t&euml; p&euml;rmendura n&euml; k&euml;t&euml; nen.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola &euml;sht&euml; nj&euml; mark&euml; e regjistruar n&euml; pron&euml;si t&euml; The Coca-Cola Company.</p>
                  <p>&nbsp;</p>
                  <p>E DREJTA P&Euml;R PJES&Euml;MARRJE</p>
                  <p>&nbsp;</p>
                  <p>Neni 6</p>
                  <p>E drejta p&euml;r t&euml; marr&euml; pjes&euml; n&euml; Program &euml;sht&euml; ekskluzivisht p&euml;r personat q&euml; banojn&euml; n&euml; Republik&euml;n e Kosov&euml;s mbi mosh&euml;n 16 vje&ccedil;.</p>
                  <p>&nbsp;</p>
                  <p>Personat e m&euml;posht&euml;m nuk kan&euml; t&euml; drejt&euml; t&euml; marrin pjes&euml; n&euml; program:</p>
                  <p>&nbsp;</p>
                  <p>Personat q&euml; jan&euml; n&euml; marr&euml;dh&euml;nie pune ose n&euml; m&euml;nyr&euml; tjet&euml;r t&euml; angazhuar me Organizatorin, si dhe bashk&euml;short&euml;t, prind&euml;rit dhe f&euml;mij&euml;t e tyre;</p>
                  <p>Personat q&euml; jan&euml; n&euml; marr&euml;dh&euml;nie pune ose jan&euml; n&euml; m&euml;nyr&euml; tjet&euml;r t&euml; angazhuar me subjektet juridike q&euml; marrin pjes&euml; n&euml; ndonj&euml; m&euml;nyr&euml; n&euml; organizimin dhe realizimin e Programit dhe/ose kan&euml; qasje n&euml; t&euml; dh&euml;nat q&euml; lidhen me p&euml;rgatitjen e programit, si dhe bashk&euml;short&euml;t, prind&euml;rit dhe f&euml;mij&euml;t e tyre.</p>
                  <p>MEKANIZMI I PJES&Euml;MARRJES N&Euml; LOJ&Euml;N E SHP&Euml;RBLIMIT</p>
                  <p>&nbsp;</p>
                  <p>Neni 7</p>
                  <p>Pjes&euml;marr&euml;sit ndjekin hapat e m&euml;posht&euml;m p&euml;r t&euml; hyr&euml; n&euml; Loj&euml;n e Shp&euml;rblimit:</p>
                  <p>&nbsp;</p>
                  <p>Bleni nj&euml; Paketim Promocional</p>
                  <p>Ruani fatur&euml;n q&euml; d&euml;shmon blerjen e Paketimit Promocional</p>
                  <p>Vizitoni www.cokenewyear.com</p>
                  <p>Jepni t&euml; dh&euml;nat personale p&euml;r t&euml; marr&euml; pjes&euml; n&euml; loj&euml;:</p>
                  <p>1. Emri</p>
                  <p>2. Mbiemri</p>
                  <p>3. Telefoni</p>
                  <p>4. Adresa</p>
                  <p>5. Emaili</p>
                  <p>6. Ngarkoni nj&euml; foto t&euml; fatur&euml;s p&euml;rkat&euml;se</p>
                  <p>7. Aplikoni p&euml;r loj&euml;n.</p>
                  <p>8. Prisni konfirmimin me email.</p>
                  <p>&nbsp;</p>
                  <p>Pas blerjes s&euml; Paketimit Promocional, &euml;sht&euml; e nevojshme t&euml; hyni n&euml; faqen e internetit www.cokenewyear.com (n&euml; vijim: faqja e internetit), t&euml; regjistroni profilin tuaj duke futur adres&euml;n e email-it dhe informacionin e k&euml;rkuar, dhe t&euml; ngarkoni nj&euml; foto p&euml;r t&euml; konfirmuar q&euml; kodi serial dhe numerik p&euml;rputhet. N&euml;se gjith&ccedil;ka &euml;sht&euml; sipas k&euml;rkes&euml;s, pjes&euml;marr&euml;si mund t&euml; aplikoj&euml; p&euml;r loj&euml;n. Fituesit dhe shp&euml;rblimet fituese gjenerohen nga kompjuteri, duke p&euml;rdorur nj&euml; algorit&euml;m t&euml; p&euml;rzgjedhjes rast&euml;sore (n&euml; vijim: Shorti).</p>
                  <p>&nbsp;</p>
                  <p>T&euml; dh&euml;nat e nevojshme p&euml;r shp&euml;rndarjen e shp&euml;rblimeve jan&euml; p&euml;rshkruar n&euml; Nenin 10 t&euml; k&euml;tyre Rregullave. N&euml; rast t&euml; t&euml; dh&euml;nave personale t&euml; paplota ose t&euml; pasakta, shp&euml;rblimi nuk do t&euml; dor&euml;zohet. Mbledhja e t&euml; dh&euml;nave do t&euml; b&euml;het ekskluzivisht p&euml;r k&euml;t&euml; q&euml;llim dhe m&euml; pas t&euml; dh&euml;nat do t&euml; fshihen si&ccedil; p&euml;rshkruhet n&euml; paragrafin 10 t&euml; Nenit 7 (P&Euml;RPUNIMI I T&Euml; DH&Euml;NAVE PERSONALE).</p>
                  <p>&nbsp;</p>
                  <p>P&euml;rzgjedhja rast&euml;sore do t&euml; bazohet n&euml; nj&euml; algorit&euml;m q&euml; mbledh t&euml; gjitha vlerat e dh&euml;na gjat&euml; p&euml;rzgjedhjes s&euml; fituesit dhe njofton fituesit rast&euml;sisht. T&euml; gjitha shp&euml;rblimet do t&euml; jen&euml; shp&euml;rblime javore, p&euml;rve&ccedil; shp&euml;rblimit kryesor q&euml; do t&euml; p&euml;rzgjidhet n&euml; fund t&euml; periudh&euml;s promovuese.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet javore do t&euml; p&euml;rzgjidhen bazuar n&euml; afatin kohor t&euml; dh&euml;n&euml; m&euml; posht&euml;. Algoritmi do t&euml; p&euml;rzgjedh&euml; fituesit nga secila jav&euml; dhe vet&euml;m p&euml;r shp&euml;rblimin kryesor do t&euml; b&euml;j&euml; p&euml;rzgjedhje nga fillimi i periudh&euml;s promovuese.</p>
                  <p>&nbsp;</p>
                  <p>Fatura me t&euml; nj&euml;jtin kod serial dhe numerik mund t&euml; p&euml;rdoret vet&euml;m nj&euml; her&euml;.</p>
                  <p>&nbsp;</p>
                  <p>Para se t&euml; shtojn&euml; informacionin, pjes&euml;marr&euml;sit do t&euml; k&euml;rkohet t&euml; konfirmojn&euml; se kan&euml; lexuar dhe pranuar k&euml;to Rregulla, t&euml; cilat jan&euml; t&euml; disponueshme n&euml; faqen e internetit.</p>
                  <p>&nbsp;</p>
                  <p>Organizatori rezervon t&euml; drejt&euml;n t&euml; skualifikoj&euml; pjes&euml;marr&euml;sit q&euml; nuk respektojn&euml; Rregullat, ose q&euml; p&euml;rmes mashtrimit, n&euml; ndonj&euml; m&euml;nyr&euml; t&euml; paligjshme, ose n&euml; kund&euml;rshtim me frym&euml;n e Loj&euml;s s&euml; Shp&euml;rblimit, marrin pjes&euml; n&euml; loj&euml;.</p>
                  <p>&nbsp;</p>
                  <p>P&Euml;RSHKRIMI I SHP&Euml;RBLIMEVE</p>
                  <p>&nbsp;</p>
                  <p>Neni 8</p>
                  <p>Si pjes&euml; integrale e Loj&euml;s s&euml; Shp&euml;rblimit, do t&euml; ndahen gjithsej 1619 shp&euml;rblime p&euml;r fituesit, t&euml; kategorizuara si m&euml; posht&euml;:</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblime Javore (Stella, Dekorim p&euml;r Vitin e Ri, Pem&euml; p&euml;r Vitin e Ri, Kuror&euml; p&euml;r Vitin e Ri, D&euml;rras&euml; druri, Prodhues Waffle, Kamera Instax Mini 12, Projektor Samsung Freestyle):</p>
                  <p>Algoritmi p&euml;rzgjedh fituesit nga vlerat e ofruara deri n&euml; or&euml;n 23:59 n&euml; dit&euml;n e p&euml;rzgjedhjes dhe njofton fituesit p&euml;rmes e-mailit t&euml; nes&euml;rmen.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimi Kryesor (Udh&euml;tim n&euml; Vjen&euml; p&euml;r Vitin e Ri):</p>
                  <p>Fituesit p&euml;rzgjidhen nga algoritmi nga t&euml; gjitha vlerat e ofruara gjat&euml; periudh&euml;s promovuese nga 25 n&euml;ntor 2024 deri m&euml; 25 dhjetor 2024. Njoftimi do t&euml; b&euml;het t&euml; nes&euml;rmen p&euml;rmes e-mailit.</p>
                  <p>&nbsp;</p>
                  <p>Afati kohor p&euml;r Shp&euml;rblimet Javore:</p>
                  <p>&nbsp;</p>
                  <p>Java I &ndash; 25.11.2024 &ndash; 02.12.2024. Njoftimi i fituesve: 03.12.2024.</p>
                  <p>Java II &ndash; 03.12.2024 &ndash; 10.12.2024. Njoftimi i fituesve: 11.12.2024.</p>
                  <p>Java III &ndash; 11.12.2024 &ndash; 18.12.2024. Njoftimi i fituesve: 19.12.2024.</p>
                  <p>Java IV &ndash; 19.12.2024 &ndash; 25.12.2024. Njoftimi i fituesve: 26.12.2024.</p>
                  <p>Rregullat p&euml;r Kodin Serial dhe Numerik:</p>
                  <p>Kodi serial dhe numerik mund t&euml; p&euml;rdoret vet&euml;m nj&euml; her&euml;, duke lejuar nj&euml; p&euml;rpjekje t&euml; vetme p&euml;r t&euml; fituar nj&euml; shp&euml;rblim me t&euml; nj&euml;jtin kod. Fatura me t&euml; nj&euml;jtin kod serial dhe numerik mund t&euml; p&euml;rdoret vet&euml;m nj&euml; her&euml;.</p>
                  <p>&nbsp;</p>
                  <p>Konfirmimi i Marr&euml;veshjes:</p>
                  <p>P&euml;rpara se t&euml; shtojn&euml; informacionin e tyre, pjes&euml;marr&euml;sit duhet t&euml; konfirmojn&euml; se kan&euml; lexuar dhe pranuar k&euml;to Rregulla, t&euml; cilat jan&euml; t&euml; disponueshme n&euml; faqen e internetit.</p>
                  <p>&nbsp;</p>
                  <p>Klauzola p&euml;r Skualifikimin:</p>
                  <p>Organizatori rezervon t&euml; drejt&euml;n t&euml; skualifikoj&euml; pjes&euml;marr&euml;sit q&euml; nuk respektojn&euml; Rregullat, angazhohen n&euml; aktivitete mashtruese ose veprojn&euml; n&euml; &ccedil;do m&euml;nyr&euml; tjet&euml;r t&euml; paligjshme, duke kund&euml;rshtuar frym&euml;n e Loj&euml;s s&euml; Shp&euml;rblimit.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimi Kryesor:</p>
                  <p>Tri udh&euml;time p&euml;r dy persona n&euml; Vjen&euml; p&euml;r Vitin e Ri gjat&euml; periudh&euml;s 30.12.2024 deri m&euml; 02.01.2025. Shp&euml;rblimi nuk mund t&euml; z&euml;vend&euml;sohet n&euml; mjete monetare dhe nuk mund t&euml; shtyhet nga ana e fituesit. Datat e shkuarjes ne Vjen&euml; jan&euml; 30 dhjetor deri m&euml; 2 Janar. N&euml; rast t&euml; pamund&euml;sis&euml; s&euml; shfryt&euml;zimit nga ana e fituesit p&euml;r arsye t&euml; ndryshme (ndalesa n&euml; l&euml;vizje, s&euml;mundje, etj.), organizatori ka t&euml; drejt t&euml; diskualifikoj fituesin.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet Javore:</p>
                  <p>&nbsp;</p>
                  <p>400 Stella Light (100 p&euml;r jav&euml;)</p>
                  <p>300 Dekorime p&euml;r Vitin e Ri (75 p&euml;r jav&euml;)</p>
                  <p>300 Pem&euml; p&euml;r Vitin e Ri (75 p&euml;r jav&euml;)</p>
                  <p>300 Kurora p&euml;r Vitin e Ri (75 p&euml;r jav&euml;)</p>
                  <p>250 D&euml;rrasa Druri (62 p&euml;r jav&euml;)</p>
                  <p>50 Prodhues Waffle (12 p&euml;r jav&euml;)</p>
                  <p>30 Kamera Instax Mini 12 (7 p&euml;r jav&euml;)</p>
                  <p>4 Projektor Samsung Freestyle (1 p&euml;r jav&euml;)</p>
                  <p>Rregullat p&euml;r Shp&euml;rblimet:</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet nuk mund t&euml; shk&euml;mbehen p&euml;r para.</p>
                  <p>Shp&euml;rblimet nuk mund t&euml; transferohen tek persona t&euml; tjer&euml;.</p>
                  <p>Organizatori garanton shp&euml;rndarjen e shp&euml;rblimeve tek fituesit, jo m&euml; von&euml; se 2 jav&euml; pas marrjes s&euml; konfirmimit nga fituesit p&euml;rmes e-mailit.</p>
                  <p>P&Euml;RPUNIMI I T&Euml; DH&Euml;NAVE PERSONALE</p>
                  <p>&nbsp;</p>
                  <p>T&euml; dh&euml;nat personale t&euml; mbledhura do t&euml; p&euml;rdoren ekskluzivisht p&euml;r q&euml;llime t&euml; dor&euml;zimit t&euml; shp&euml;rblimeve dhe nuk do t&euml; transferohen tek asnj&euml; pal&euml; e tret&euml;, n&euml; p&euml;rputhje me rregulloren e p&euml;rgjithshme p&euml;r mbrojtjen e t&euml; dh&euml;nave (GDPR), p&euml;rve&ccedil; Agjencis&euml; VM3 e cila &euml;sht&euml; e angazhuar p&euml;r shp&euml;rndarjen e shp&euml;rblimeve. T&euml; gjitha t&euml; dh&euml;nat do t&euml; fshihen pas p&euml;rfundimit t&euml; Loj&euml;s s&euml; Shp&euml;rblimit.</p>
                  <p>&nbsp;</p>
                  <p>DETYRIMET E ORGANIZATORIT DHE PJES&Euml;MARR&Euml;SIT</p>
                  <p>&nbsp;</p>
                  <p>Neni 10</p>
                  <p>Organizatori siguron zbatimin korrekt t&euml; Loj&euml;s s&euml; Shp&euml;rblimit, ruan konfidencialitetin e t&euml; dh&euml;nave t&euml; pjes&euml;marr&euml;sve dhe i p&euml;rgjigjet ankesave. Pjes&euml;marr&euml;sit duhet t&euml; respektojn&euml; rregullat, t&euml; japin t&euml; dh&euml;na t&euml; sakta dhe t&euml; ruajn&euml; fatur&euml;n origjinale p&euml;r shp&euml;rblimet kryesore deri n&euml; fund t&euml; Loj&euml;s s&euml; Shp&euml;rblimit.</p>
                  <p>&nbsp;</p>
                  <p>PROCEDURA P&Euml;R REALIZIMIN E T&Euml; DREJT&Euml;S P&Euml;R SHP&Euml;RBLIM</p>
                  <p>&nbsp;</p>
                  <p>Neni 11</p>
                  <p>Pjes&euml;marr&euml;sit do t&euml; din&euml; kur jan&euml; shpallur fitues duke marr&euml; nj&euml; e-mail n&euml; kutin&euml; e tyre postare (E-mail Konfirmimi). Modeli i E-mailit t&euml; Konfirmimit p&euml;r fituesit e shp&euml;rblimeve &euml;sht&euml; si m&euml; posht&euml;:</p>
                  <p>Urime! Ju keni fituar me sukses __________ nga Coca Cola New Year NCP.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet do t&euml; shp&euml;rndahen jo m&euml; von&euml; se 15 janar 2025.</p>
                  <p>&nbsp;</p>
                  <p>P&euml;r shp&euml;rblimin q&euml; t&euml; dor&euml;zohet ose merret, &euml;sht&euml; e nevojshme q&euml; t&euml; gjitha t&euml; dh&euml;nat e k&euml;rkuara t&euml; jen&euml; t&euml; sakta dhe t&euml; referohen tek nj&euml; person. N&euml; rast t&euml; dh&euml;nave t&euml; paplota ose t&euml; pasakta, dhurata nuk do t&euml; dor&euml;zohet.</p>
                  <p>Shp&euml;rblimet do t&euml; dor&euml;zohen p&euml;rmes Agjencis&euml; VM3 n&euml; adres&euml;n e raportuar nga fituesi, jo m&euml; von&euml; se 2 jav&euml; pas marrjes s&euml; e-mailit nga fituesi. N&euml;se fituesi nuk &euml;sht&euml; n&euml; adres&euml; gjat&euml; koh&euml;s s&euml; dor&euml;zimit, VM3 do t&euml; provoj&euml; ta dor&euml;zoj&euml; dhurat&euml;n edhe dy her&euml; t&euml; tjera, n&euml; t&euml; nj&euml;jt&euml;n adres&euml;. N&euml;se fituesi nuk gjendet as gjat&euml; k&euml;tyre dy p&euml;rpjekjeve, fituesi humbet t&euml; drejt&euml;n p&euml;r shp&euml;rblim.</p>
                  <p>&nbsp;</p>
                  <p>DISPOZITAT FINALE</p>
                  <p>&nbsp;</p>
                  <p>Neni 12</p>
                  <p>Pjes&euml;marr&euml;sit, duke marr&euml; pjes&euml;, konfirmojn&euml; kuptimin dhe pranimin e k&euml;tyre rregullave. Organizatori rezervon t&euml; drejt&euml;n p&euml;r t&euml; ndryshuar rregullat, t&euml; cilat hyjn&euml; n&euml; fuqi pas publikimit n&euml; www.cokenewyear.com. Pjes&euml;marr&euml;sit do t&euml; informohen p&euml;r ndryshimet n&euml; t&euml; nj&euml;jt&euml;n faqe. Organizatori nuk mban p&euml;rgjegj&euml;si p&euml;r problemet teknike q&euml; pengojn&euml; qasjen n&euml; faqe.</p>
                  <p>&nbsp;</p>
                  <p>Neni 13</p>
                  <p>Ankesat duhet t&euml; dor&euml;zohen me shkrim n&euml; adres&euml;n e Organizatorit t&euml; p&euml;rcaktuar n&euml; Nenin 1 brenda 10 dit&euml;ve nga shkelja e pretenduar. Organizatori p&euml;rgjigjet brenda 15 dit&euml;sh.</p>
                  <p>&nbsp;</p>
                  <p>Neni 14</p>
                  <p>N&euml; rast t&euml; mosp&euml;rputhjeve, interpretimi i Organizatorit &euml;sht&euml; vendimtar.</p>
                  <p>&nbsp;</p>
                  <p>Neni 15</p>
                  <p>Gjykata kompetente n&euml; Prishtin&euml; ka juridiksion mbi mosmarr&euml;veshjet q&euml; rrjedhin nga Loja e Shp&euml;rblimit.</p>
                  <p>&nbsp;</p>
                  <p>Neni 16</p>
                  <p>K&euml;to rregulla jan&euml; t&euml; disponueshme n&euml; faqen e internetit www.cokenewyear.com.</p>
                  <p>&nbsp;</p>
                  <p>Mb&euml;shtetja ndaj klientit: 049 74 55 70 ncp@vmtre.com</p>
                  <p>&nbsp;</p>
                  <p>Prishtin&euml;, N&euml;ntor 2024.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola Hellenic Bottling Company - Kosovo LLC</p>
                </>
                :

                <>
                  <p>"COKE NEW YEAR" PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>ORGANIZER</p>
                  <p>&nbsp;</p>
                  <p>Article 1</p>
                  <p>Coca-Cola Hellenic Bottling Company &ndash; Kosovo Coca-Cola Hellenic Bottling Company - Kosovo LLC, based in Pristina at Lapnasell&euml;, Fifth Kilometer of Pristina-Skopje Road, 14000 Lipjan &ndash; Kosovo, tax number 600299384, is the organizer of the "COKE NEW YEAR" prize game (hereinafter referred to as the "Prize Game"). These rules are binding for all participants.</p>
                  <p>&nbsp;</p>
                  <p>OFFICIAL NAME OF THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 2</p>
                  <p>The official name of the Prize Game is "Coke New Year."</p>
                  <p>&nbsp;</p>
                  <p>TERRITORY</p>
                  <p>&nbsp;</p>
                  <p>Article 3</p>
                  <p>The Prize Game will take place exclusively within the territory of Kosovo.</p>
                  <p>&nbsp;</p>
                  <p>DURATION OF THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 4</p>
                  <p>Coke New Year starts on November 25th, 2024, at 00:01, and ends on December 25th 2024, at 23:59, the Prize Game will adhere to these rules.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>PROMOTIONAL PACKAGES AND ORGANIZATION PURPOSE</p>
                  <p>&nbsp;</p>
                  <p>Article 5</p>
                  <p>Participating products and packages, collectively referred to as "Promotional Packages," include Coca Cola Red 0.25L in returnable glass bottle (hereinafter: RGB), Coca Cola Zero 0.25L RGB, Coca Cola Red 0.33L, Coca Cola Zero 0.33L and Coca-Cola Cherry 0.33L in cans, and Coca Cola Red 0.45L, Coca Cola Zero 0.45L, Coca Cola Red 1.25L, Coca Cola Zero 1.25L, Coca Cola Cherry 1.25L, Coca Cola Red 2L, and Coca Cola Zero 2L in non-returnable plastic bottles. The Prize Game aims to promote the products outlined in this article.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola is a registered trademark owned by The Coca-Cola Company.</p>
                  <p>&nbsp;</p>
                  <p>RIGHT TO PARTICIPATE</p>
                  <p>&nbsp;</p>
                  <p>Article 6</p>
                  <p>The right to participate in the Program is exclusively for persons residing in the Republic of Kosovo above 16 years old.</p>
                  <p>&nbsp;</p>
                  <p>The following persons have the right not to participate in the program:</p>
                  <p>- Persons who are in a working relationship or otherwise engaged with the Organizer as well as their spouses, parents and children;</p>
                  <p>- Persons who are in a working relationship or are otherwise engaged with legal entities that participate in any way in the organization and organization of the Program and/or have access to data related to the preparation of the program, as well as their spouses, parents and children.</p>
                  <p>&nbsp;</p>
                  <p>PARTICIPATION MECHANISM IN THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 7</p>
                  <p>Participants follow the below steps to enter the Prize Game:</p>
                  <p>&nbsp;</p>
                  <p>1. Buy Promotional package</p>
                  <p>2. Save receipt that proves you bought Promotional package</p>
                  <p>3. Visit www.cokenewyear.com</p>
                  <p>4. Provide personal data in order to participate in the game:</p>
                  <p>&middot; Name</p>
                  <p>&middot; Surname</p>
                  <p>&middot; Phone</p>
                  <p>&middot; Address</p>
                  <p>&middot; Email</p>
                  <p>5. Upload a matching picture of the invoice.</p>
                  <p>6. Apply for the game.</p>
                  <p>7. Await an email confirmation.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>After the participant buys Promotional package, it is necessary to log on to the website www.cokenewyear.com (hereinafter: website), register their profile by entering their email address and the required information, and take a picture to confirm that the serial and numeric code match. If everything is according to the request, the participant can go through and apply for the game. Winners and the prizes won are generated by computer, randomly selected using the algorithm (hereinafter: Draw).</p>
                  <p>The information needed to deliver the prizes is described in Article 10 of these Rules. In case of incomplete or inaccurate personal data, the prize will not be delivered. Data collection will be done exclusively for this purpose, and then the data will be deleted as described in paragraph 10 of Article 7 (PROCESSING OF PERSONAL DATA).</p>
                  <p>The random selection will be based on an algorithm which collects all the values provided upon the winner selection and announces the winners randomly. All the prizes will be weekly prizes except for the main prize which will be selected in the end of the promotional period. Weekly prizes will be selected based on the timeline provided below. Algorithm will select winers from each week and only for the main prize will select from beginning of promotional period.</p>
                  <p>The invoice with the same serial and numeric number can be used only once.</p>
                  <p>Before adding the info, participants will be required to confirm that they have read and agreed to these Rules, available on the website.</p>
                  <p>The Organizer reserves the right to disqualify participants who do not respect the Rules or who, through fraud or in any other unlawful manner or in contradiction with the spirit of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>DESCRIPTION OF PRIZES</p>
                  <p>Article 8</p>
                  <p>As an integral component of the Prize Game, a total of 1619 prizes will be awarded to winners, categorized as follows:</p>
                  <p>&nbsp;</p>
                  <p>Weekly Prizes (Stella, New Year Decoration, New Year Tree, New Year Wrath, Wooden board, Waffle maker, Instax Mini 12 camera, Samsung Freestyle Projector):</p>
                  <p>The algorithm selects winners from the values provided on the selection day until 23:59h and announces the winner via e-mail in the next day.</p>
                  <p>&nbsp;</p>
                  <p>Main Prize (Trip to Vienna for New Year):</p>
                  <p>The winners are selected by algorithm from all values provided throughout the promotion period from November 25th, 2024, to December 25th, 2024. The announcement will be made on the next day through e-mail. Prize cannot be converted in money and cannot be delayed from the winner. Dates of the trip to Vienna are from 30th of December 2024 until 2nd of January 2025. In case if the winner cannot receive their prize due to uncontrollable circumstances (e.g. Travel Restrictions, Illness, etc.), the organizer preserves the right to disqualify the winner.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>Timeline for Weekly Prizes:</p>
                  <p>&nbsp;</p>
                  <p>I week &ndash; 25.11.2024 &ndash; 02.12.2024. Winner announcement: 3.12.2024.</p>
                  <p>II week &ndash; 3.12.2024 to 10.12.2024. Winner announcement: 11.12.2024.</p>
                  <p>III week &ndash; 11.12.2024 to 18.12.2024. Winner announcement: 19.12.2024.</p>
                  <p>IV week &ndash; 19.12.2024 to 25.12.2024. Winner announcement: 26.12.2024.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>Serial and Numeric Code Regulations:</p>
                  <p>&nbsp;</p>
                  <p>The serial and numeric code can be set only once, allowing a single attempt to win a prize with the same code. The invoice with the same serial and numeric code can be used only once.</p>
                  <p>&nbsp;</p>
                  <p>Confirmation of Agreement:</p>
                  <p>Before adding their information, participants will be required to confirm that they have read and agreed to these Rules, which are available on the website.</p>
                  <p>&nbsp;</p>
                  <p>Disqualification Clause:</p>
                  <p>The Organizer reserves the right to disqualify participants who do not adhere to the Rules, engage in fraudulent activities, or act in any other unlawful manner, contradicting the spirit of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>Main Prize:</p>
                  <p>&nbsp;</p>
                  <p>Three trips for two persons in Vienna for New Year on the period 30.12.2024 to 02.01.2025</p>
                  <p>&nbsp;</p>
                  <p>Weekly Prizes:</p>
                  <p>&nbsp;</p>
                  <p>&middot; Stella light x400 (100 per week)</p>
                  <p>&middot; New Year Decoration x300 (75 per week)</p>
                  <p>&middot; New Year Tree x300 (75 per week)</p>
                  <p>&middot; New Year Wrath x300 (75 per week)</p>
                  <p>&middot; Wooden Board x250 (62 per week)</p>
                  <p>&middot; Waffle Maker x50 (12 per week)</p>
                  <p>&middot; Instax Mini 12 Camera x30 (7 per week)</p>
                  <p>&middot; Samsung Freestyle Projector x4 (1 per week)</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>Prize Regulations:</p>
                  <p>&nbsp;</p>
                  <p>Prizes cannot be exchanged for cash.</p>
                  <p>Prizes cannot be transferred to other persons.</p>
                  <p>The Organizer guarantees the distribution of prizes to the winners, in no longer than 2 weeks&rsquo; after they receive confirmation from the prize they have won via e-mail.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>PROCESSING OF PERSONAL DATA</p>
                  <p>&nbsp;</p>
                  <p>Personal data collected will be used exclusively for prize delivery purposes and will not be forwarded to any third party in coordination with general data protection regulation (GDPR)., except VM3 Agency which agency is engaged for prizes delivery. All data will be deleted post-Prize Game completion.</p>
                  <p>&nbsp;</p>
                  <p>OBLIGATIONS OF THE ORGANIZER AND PARTICIPANT</p>
                  <p>&nbsp;</p>
                  <p>Article 10</p>
                  <p>The Organizer ensures proper implementation of the Prize Game, maintains participant data confidentiality, and responds to complaints. Participants must respect the rules, provide accurate data, and retain the original invoice for main prizes until the end of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>PROCEDURE FOR REALIZING THE RIGHT TO THE PRIZE</p>
                  <p>&nbsp;</p>
                  <p>Article 11</p>
                  <p>Participants will know when they have been the selected winner by receiving an e-mail in their inbox (Confirmation Email). The layout of the Confirmation Email for award winners is as follows:</p>
                  <p>_______________________________ Congratulations! You have successfully won _______________ from Coca Cola New Year NCP.</p>
                  <p>&nbsp;</p>
                  <p>*All winners will receive their prizes no later than 15th of January 2025.</p>
                  <p>&nbsp;</p>
                  <p>For the prize to be delivered or received, it is necessary for all the requested data to be correct and to refer to a person in case of submission of incomplete or incorrect personal data, the gift will not be delivered.</p>
                  <p>Prizes will be delivered via VM3 agency to the address reported by the winner, no later than 2 weeks after the winner receives the email. The right to receive the prize will have only the person specified in the information submitted to the e-mail address after winning the prize and no other person. In case the winner is not at the address at the time of delivery, VM3 will try to deliver the prize two more times, at the same address. If the winner is not at the address during those two times, the winner loses the right to prize.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>FINAL PROVISIONS</p>
                  <p>&nbsp;</p>
                  <p>Article 12</p>
                  <p>Participants, by participating, confirm understanding and acceptance of these rules. The Organizer reserves the right to change rules, effective upon publication on www.cokenewyear.com. Participants will be informed of changes on the same website. The Organizer is not liable for technical issues preventing website access.</p>
                  <p>&nbsp;</p>
                  <p>For matters not covered by these rules, the laws of the Republic of Kosovo apply.</p>
                  <p>&nbsp;</p>
                  <p>Article 13</p>
                  <p>Complaints must be submitted in writing to the Organizer's address specified in Article 1 within 10 days of the alleged violation. The Organizer responds within 15 days.</p>
                  <p>&nbsp;</p>
                  <p>Article 14</p>
                  <p>In case of discrepancies, the Organizer's interpretation prevails.</p>
                  <p>&nbsp;</p>
                  <p>Article 15</p>
                  <p>The competent court in Pristina has jurisdiction over disputes arising from the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>Article 16</p>
                  <p>These rules are available on www.cokenewyear.com.</p>
                  <p>&nbsp;</p>
                  <p>Customer Support: 049 74 55 70</p>
                  <p>ncp@vmtre.com</p>
                  <p>&nbsp;</p>
                  <p>Pristina, November 20, 2024.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola Hellenic Bottling Company - Kosovo LLC, Pristina</p>
                </>
              }
            </div>
          </div>
          <img src={rightImg} alt="rightCCImg" className="rightImg" />
        </div>
      </>
    );
}

export default Terms;