import axios from 'axios';

let apitoken = window.localStorage.getItem('ccToken');
// console.log('token', apitoken);
const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: 'Bearer ' + apitoken,
    },
});
defaultInstance.interceptors.response.use(req =>{
    // console.log('interceptorsreq', req.status);
    if(req.status === 401){
    window.localStorage.clear();
    window.location.reload();
    }
    return req
})

export default defaultInstance;
