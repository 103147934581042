import React, { useEffect, useState } from "react";
import leftImg from "./../images/cc-left.png";
import rightImg from "./../images/cc-right.png";
import bottleImg from "./../images/cc-bottle.png";
import kuponFiskal from "./../images/validTicketPhoto.png";
import kuponFiskal2 from "./../images/eBardhe.png";
import Loading from "./loading";
import axios from "./../Axios";
import { Button, Overlay, Popover } from "react-bootstrap";
import Tesseract from "tesseract.js";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ModalDialog from "./modal/viewApplicantModal";
import LoadingAnimation from './../images/coca-cola-beats.gif';
import { Alert } from "@mui/material";
import HowToPlayModalDialog from "./modal/HowToPlayModalDialog";

const Home = (props) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [trans, setTrans] = useState(false);
  const [lang, setLang] = useState("al");

  const [dataSubmit, setDataSubmit] = useState({});

  const [showPopover, setShowPopover] = useState(false);
  const [howToPlay, setHowToplay] = useState(false);

  const [image, setImage] = useState(null);
  const [extractedText, setExtractedText] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [isSubmiting, setIsSubmiting] = useState(false);




  const resizeImage = (base64Str, maxWidth = 1280, maxHeight = 1920) => {
    setImageUrl(base64Str)
    setIsLoading(true)
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg', 0.85));
      };
    });
  };

  const processImage = (imageSrc) => {
    // setImage(imageSrc);


    Tesseract.recognize(
      imageSrc,
      'eng',
      // { logger: m => console.log(m) }
    ).then(({ data: { text } }) => {
      const foundText = ["Coca Cola", "Coca-Cola", "COCA COLA", "COCA-COLA", "Cola", "Coca", "coca cola", "coca-cola", "Coca-cola", "coca-Cola", "coca", "cola", "Pije e gazuar", "pije e gazuar"].some(variant => text.includes(variant));
      console.log("foundText", text)
      setExtractedText(foundText ? 'Found "coca cola" in the text.' : `"coca cola" not found in the text.`);
      setIsLoading(false);
    });
  };


  // const handleMouseLeave = () => {
  //   setShowPopover(false);
  // };
  const handleChange = (e) => {
    const ud = Object.assign({}, dataSubmit);
    if (e.target.name === "name_surname") {
      e.preventDefault();
      ud.name_surname = e.target.value;
    }
    if (e.target.name === "phone") {
      e.preventDefault();
      ud.phone = e.target.value;
    }
    if (e.target.name === "email") {
      e.preventDefault();
      ud.email = e.target.value;
    }
    // if (e.target.name === "nrSerik") {
    //   e.preventDefault();
    //   ud.nrSerik = e.target.value;
    // }
    if (e.target.name === "address") {
      e.preventDefault();
      ud.address = e.target.value;
    }
    setDataSubmit(ud);
  };

  // console.log("image", image)
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmiting(true)

    if (image && image.size > 5 * 1024 * 1024) {
      toast.error("Fotografia nuk duhet të jetë më e madhe se 5 MB");
      setImageUrl("")
      setExtractedText(`"coca cola" not found in the text.`);
      setImage(null)
      setIsSubmiting(false);
      return;
    }
    const formData = new FormData();
    formData.append("ticket_photo", image);
    formData.append("name_surname", dataSubmit?.name_surname);
    formData.append("phone_number", dataSubmit?.phone);
    formData.append("email", dataSubmit?.email);
    formData.append("address", dataSubmit?.address);

    process.env.NODE_ENV === "development" && console.log("formData", formData);

    axios.post("/general/apply", formData)
      .then((data) => {
        setIsSubmiting(false)
        setImage(null)
        setImageUrl("")
        setDataSubmit({
          name_surname: "",
          phone: "",
          email: "",
        });
        navigate("/success");
        toast.success("Aplikimi u krye me sukses!");

        process.env.NODE_ENV === "development" && console.log("post", data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Diqka shkoi gabim, provoni përsëri!")
        setIsSubmiting(false)
        console.log("err", err?.response?.data);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const handleLangChange = (e) => {
    e.preventDefault();
    if (lang === "al") {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "en");
        setLang("en");
      }, 200);
    } else {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "al");
        setLang("al");
      }, 200);
    }
  };

  const translateKey = (data, lang) => {
    if (lang === "al") {
      // let trans =  labels !== undefined &&  labels.length > 0  && labels?.filter((label) => {return label?.label === data});
      let trans = props?.labels?.filter((label) => {
        return label?.label === data;
      });
      // console.log('translate', trans[0]?.content_al);
      return trans[0]?.content_al;
    } else {
      // let trans =  labels !== undefined &&  labels.length > 0  &&  labels?.filter((label) => {return label?.label === data});
      let trans = props?.labels?.filter((label) => {
        return label?.label === data;
      });
      return trans[0]?.content_en;
    }
  };

  trans === true && console.log("{ labels }");

  if (loading) {
    return <Loading />;
  }

  if (props?.labels)
    return (
      <>
        {showPopover && <ModalDialog isOpen={showPopover} handleModalClose={() => setShowPopover(!showPopover)} lang={lang} />}
        {howToPlay && <HowToPlayModalDialog isOpen={howToPlay} handleModalClose={() => setHowToplay(!howToPlay)} lang={lang} />}
        <div className=" container langWrapper my-4">
          <button className="form-control " onClick={handleLangChange}>
            {lang === "en" ? "English" : "Shqip"}
          </button>
          <button className="form-control " onClick={()=>setHowToplay(!howToPlay)}>
            {lang === "en" ? "How to play?" : "Si të luaj?"}
          </button>

          {/* <h4 className="nav_link">Prizes</h4> */}

        </div>
        <div class="container align-items-center">
          <div className="data">
            <div className="title text-center">
              <h3>{props?.labels && translateKey("homeTitle", lang)}</h3>
              {/* <br /> */}
              {/* <h3>
                {props?.labels && translateKey("homeTitleHighlight", lang)}
              </h3> */}
            </div>
            <div className="text-center">
              {/* <h3>{props?.labels && translateKey("homeSubtitle", lang)}</h3> */}
            </div>
            <div className="text-center">
              <h5>
                {props?.labels && translateKey("homeDesctiption", lang)}
              </h5>
            </div>
            <div className="text-center aplication_form">
              <img src={leftImg} alt="" className="leftImg" />
              <form onSubmit={handleSubmit} className="submit_Form">
                {/* <label>Emri</label> */}
                <div className="row">
                  <div className="col-12">
                    <input
                      value={dataSubmit?.name_surname}
                      className="form-control p-2 mt-3"
                      required
                      placeholder={
                        props?.labels && translateKey("homeInputName", lang)
                      }
                      name="name_surname"
                      onChange={handleChange}
                    ></input>
                  </div>
                  {/* <label>Addressa</label> */}

                  <div className="col-12">
                    <input
                      className="form-control p-2 mt-3"
                      required
                      value={dataSubmit?.address}
                      placeholder={
                        props?.labels && translateKey("address", lang)
                      }
                      name="address"
                      type="text"
                      onChange={handleChange}
                    ></input>
                  </div>
                  {/* <label>Nr Telefonit</label> */}
                  <div className="col-12">
                    <input
                      className="form-control p-2 mt-3"
                      required
                      value={dataSubmit?.phone}
                      placeholder={
                        props?.labels && translateKey("homeInputPhone", lang)
                      }
                      name="phone"
                      type="number"
                      onChange={handleChange}
                    ></input>
                  </div>

                  {/* <label>E-mail Adresa</label> */}
                  <div className="col-12">
                    <input
                      className="form-control p-2 mt-3"
                      required
                      placeholder={
                        props?.labels && translateKey("homeInputEmail", lang)
                      }
                      value={dataSubmit?.email}
                      name="email"
                      type="email"
                      onChange={handleChange}
                    ></input>
                  </div>
                  {/* <label>Nr Serik</label> */}
                  <div className="d-flex align-items-center">

                  </div>


                  <div className="d-flex flex-sm-row flex-column align-items-center 
                  flex-xl-row
                  flex-lg-row
                  flex-md-row
                  flex-column-reverse">
                    <div className="col-md-6  mt-2 ">
                      <div className="row justify-content-center  ">

                        <label className=" py-3 col-md-9 col-12 custom-file-upload d-flex align-items-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              console.log('eventi dajko', e);
                              setImage(e.target.files[0]);

                              resizeImage(URL?.createObjectURL(e?.target?.files[0]))
                                .then(resizedImage => {
                                  processImage(resizedImage);
                                  // Reset the input value to allow selecting the same file again
                                  e.target.value = '';
                                });
                            }}
                          />
                          {lang === "en" ? "TAKE A PHOTO OF THE INVOICE" : " FOTOGRAFO FATURËN"}
                          <i class="fa-solid ms-2 fa-camera" style={{ fontSize: "22px" }}></i>

                        </label>
                      </div>
                      <div className="nav_link text-decoration-none   mt-2" role="button" onClick={(e) => { e.preventDefault(); setShowPopover(true) }}>
                        Si të bëni fotografinë?
                      </div>
                    </div>

                    <div className="col-md-6 col-12 mt-3 upload_image">
                      {
                        imageUrl == "" ?
                          // <img
                          //   src={kuponFiskal2}
                          //   alt="Selected"
                          //   className="img-fluid default_image"
                          // />
                          <div className="default_image">
                            <span>
                              Fatura juaj
                            </span>
                          </div>
                          :
                          <div className="clasa">

                            {imageUrl && (
                              <img
                                src={imageUrl}
                                alt="Selected"
                                className="img-fluid"
                              />
                            )}
                          </div>
                      }

                    </div>
                  </div>
                  <div class="col-12 my-4 form-check d-flex align-items-center
                  flex-xl-row
                  flex-lg-row
                  flex-md-row
                  flex-column-reverse
                  ps-0
                  ">
                    <input
                      required

                      type={"checkbox"}
                      className="  ms-sm-2 ms-0 form-check-input"
                      name="terms"
                      id="flexCheckChecked"
                      onChange={handleChange}
                      checked={dataSubmit?.terms}
                    />
                    <span
                      class="form-check-label 
                      align-lg-items-start
                      align-xl-items-start
                      align-md-items-start
                      align-items-center d-xl-block  d-flex flex-column    ms-sm-2 ms-0  mt-1"
                      for="flexCheckChecked"
                    >

                      {props?.labels && translateKey("iAgreeWith", lang)}
                      <Link to="/terms" className="nav_link text-decoration-none text-white ps-1">
                        {props?.labels && translateKey("termatDheKushtetElojes", lang)}
                      </Link>
                    </span>
                  </div>
                  {/* <div class="form-check ">
                    <input
                      required
                      type={"checkbox"}
                      className="form-check-input"
                      name="terms"
                      onChange={handleChange}
                      checked={dataSubmit?.terms}
                    />
                    <label
                      class="form-check-label align-items-start d-flex"
                      for="flexCheckChecked"
                    >
                      {props?.labels && translateKey("homeTerms", lang)}
                      <Link
                        to="/terms"
                        className="text-decoration-none text-white ps-1"
                      >
                        {props?.labels && translateKey("homeTermsButton", lang)}
                      </Link>
                    </label>
                  </div> */}
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <div className="buttonContainer">
                    <div className="mt-3">
                      {isLoading ?
                        <button className="submitButton1" type="submit" disabled>
                          <span >
                            Duke skanuar faturën tuaj
                          </span>
                          <img src={LoadingAnimation} className="dancingLoader" alt="Dancing Cola" />

                        </button>
                        :
                        <button disabled={extractedText !== 'Found "coca cola" in the text.' || isSubmiting && true} className="submitButton" type="submit">
                          <labels className="p-2">
                            {console.log("extracted", extractedText)}
                            {extractedText === 'Found "coca cola" in the text.' ?
                              props?.labels && translateKey("homeSubmitButton", lang) : extractedText ==
                                "" ? props?.labels && translateKey("homeSubmitButton", lang) : lang == 'al' ? `"Coca Cola" nuk u gjet në faturën tuaj!` : "Coca Cola not found in your ticket!"
                            }

                          </labels>
                          <img
                            alt=""
                            src={bottleImg}
                            className="submitButtonImg"
                          />
                        </button>

                      }
                    </div>

                  </div>
                </div>
                {console.log("extractedText", extractedText)}
                {extractedText === "" ?

                  ""
                  :
                  extractedText !== 'Found "coca cola" in the text.' &&
                  <Alert severity="warning" className=' mt-4 d-flex align-items-center ' >
                    {lang == "al" ?
                      "Sigurohuni që teksti në faturë, veçanërisht Coca Cola, të mos jetë e shtrembëruar dhe të jetë qartësisht e dukshme, pasi çdo shtrembërim mund të pengojë OCR (Njohja Optike e Karaktereve) ta njohë atë me saktësi."
                      :
                      "Ensure that the text on the invoice, especially the Coca Cola branding, is not distorted and is clearly visible, as any distortion may prevent OCR (Optical Character Recognition) from accurately recognizing it."
                    }
                  </Alert>
                }
              </form>

              <img src={rightImg} alt="" className="rightImg" />
            </div>
          </div>
        </div>
      </>
    );
};

export default Home;